import { combineReducers } from 'redux'
import reducer from './reducer'
import reducerLoadImages from './reducerLoadImages'
import reducerDimensions from './reducerDimensions'
import reducerUI from './reducerUI'
import reducerPDF from './reducerPDF'

export default combineReducers({
  reducer,
  loadImages: reducerLoadImages,
  dimensions: reducerDimensions,
  ui: reducerUI,
  pdf: reducerPDF
})
