import React from 'react'
import { useSelector } from 'react-redux'

import IconLoading from './IconLoading'

const PdfLoading = () => {
  const pdfLoading = useSelector(state => state.pdf.pdfLoading)

  if (pdfLoading) {
    return (
      <div className="resumen" style={{ zIndex: 3 }}>
        <IconLoading style={{ top: 'calc(50% - 32px)' }} />
      </div>
    )
  }
  return null
}

export default PdfLoading
