import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useSelector } from 'react-redux'

import { useSpring, animated, interpolate } from 'react-spring'

const useStyles = createUseStyles({
  image: {
    willChange: 'opacity',
    width: props => props.sizeImage.width,
    height: props => props.sizeImage.height,
    position: 'absolute',
    left: props => props.position.left,
    top: props => props.position.top
  }
})

const ImageEstancia = ({ src, width, height, visible, prev, next, x }) => {
  const sizeContainer = useSelector(
    state => state.dimensions.sizeImageContainer
  )

  const { opacity } = useSpring({ opacity: visible ? 1 : 0 })

  const sizeImage = {}
  const position = {}

  const ratioImage = width / height

  const theme = useTheme()

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let transform = 'translate3d(0,0,0)'

  if (mq.matches) {
    sizeContainer.height = 257
    transform = interpolate([x], x => {
      return `translate3d(${x}px,0,0)`
    })
  }

  const ratioContainer = sizeContainer.width / sizeContainer.height

  if (ratioContainer < ratioImage) {
    sizeImage.width = sizeContainer.width
    sizeImage.height = sizeContainer.width / ratioImage
    position.left = 0
    position.top = (sizeContainer.height - sizeImage.height) / 2
  } else {
    sizeImage.width = sizeContainer.height * ratioImage
    sizeImage.height = sizeContainer.height
    position.left = (sizeContainer.width - sizeImage.width) / 2
    position.top = 0
  }

  if (prev) {
    position.left = position.left - sizeContainer.width
  }

  if (next) {
    position.left = position.left + sizeContainer.width
  }

  const classes = useStyles({ sizeImage, position })

  return (
    <animated.img
      className={classes.image}
      alt=""
      src={src}
      style={{
        opacity,
        transform
      }}
    />
  )
}

export default ImageEstancia
