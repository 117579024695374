import React, { useState } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import Measure from 'react-measure'

import WrapperButtonsSlider from '../SliderEstancias/WrapperButtonsSlider'

const useStyles = theme =>
  createUseStyles({
    footer: {
      backgroundColor: ({ theme }) => theme.backgroundFooter,
      minHeight: '10%'
    },
    footerLogoContainer: {
      padding: '15px 30px 15px 0 !important'
    },
    footerLogo1: {
      height: '100%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',

      backgroundPosition: 'center',
      backgroundImage: 'url(logopie1.png)'
    },
    [`@media screen and (max-width: ${theme.mediaScroll})`]: {
      footer: {
        minHeight: '200px'
      },

      footerLogoContainer: {
        padding: '0 !important',
        height: '70px',
        marginBottom: '10px !important'
      }
    }
  })

const Footer = () => {
  const [heightFooter, setHeightFooter] = useState(0)

  const theme = useTheme()

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let padding = 30

  if (!mq.matches) {
    padding = (heightFooter - 41.6) / 2
  }

  const classes = useStyles(theme)({ theme })

  return (
    <Measure
      bounds
      onResize={contentRect => {
        setHeightFooter(contentRect.bounds.height)
      }}
    >
      {({ measureRef }) => (
        <div
          ref={measureRef}
          className={`${classes.footer} col-12 grid-noGutter`}
        >
          <div
            className="col-9_sm-12"
            style={{ padding: `${padding}px 0 0 0` }}
          >
            <WrapperButtonsSlider />
          </div>

          <div
            className={`${classes.footerLogoContainer} col-3_sm-12 grid-noGutter`}
          >
            <div className="col-4 sm-hidden" />
            <a className="col-8_sm-12" href="#">
              <div className={classes.footerLogo1} />
            </a>
          </div>
        </div>
      )}
    </Measure>
  )
}

export default Footer
