import React from 'react'
import './IconLoading.css'
import theme from './Theme'

const IconLoading = ({ style }) => (
  <div className="icon-loading" style={style}>
    <svg width={64} height={64} viewBox="0 0 128 128">
      <g>
        <linearGradient id="prefix__a">
          <stop offset="0%" stopColor="#fff" fillOpacity={0} />
          <stop offset="100%" stopColor={theme.colorPrincipal} />
        </linearGradient>
        <path
          d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
          fill="url(#prefix__a)"
          fillRule="evenodd"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 64 64"
          to="360 64 64"
          dur="1080ms"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  </div>
)

export default IconLoading
