import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getMenu } from '../../modules/reducer'
import { toggleOpenMenu } from '../../modules/reducerUI'

import { useSpring, animated } from 'react-spring'

const useStyles = createUseStyles({
  menuV: {
    alignSelf: 'center',
    position: 'absolute',
    right: 0,
    color: 'white',
    fontSize: '0.8em',
    zIndex: 10,
    backgroundColor: ({ theme }) => theme.colorPrincipal,
    overflow: 'hidden',
    top: 0,
    width: '35%'
  },
  menuVAIngles: {
    padding: '10px',
    cursor: 'pointer',
    fontWeight: props => (props.idioma === 'en' ? 500 : 300)
  },
  menuVAEspa: {
    padding: '10px',
    cursor: 'pointer',
    fontWeight: props => (props.idioma === 'es' ? 500 : 300)
  }
})

const MenuV = ({ history }) => {
  const dispatch = useDispatch()
  const menu = useSelector(getMenu)
  const idioma = menu.idioma
  const path = menu.path
  const visible = useSelector(state => state.ui.openMenu)

  const dEnToggleOpenMenu = useCallback(
    e => {
      history.push(`/en/${path}`)
      dispatch(toggleOpenMenu())
    },
    [dispatch, history, path]
  )

  const dEsToggleOpenMenu = useCallback(
    e => {
      history.push(`/es/${path}`)
      dispatch(toggleOpenMenu())
    },
    [dispatch, history, path]
  )

  const { height } = useSpring({
    height: visible ? 85 : 0
  })

  const theme = useTheme()
  const classes = useStyles({ idioma, theme })

  return (
    <animated.div
      className={classes.menuV}
      style={{
        height
      }}
    >
      <div className={classes.menuVAIngles} onClick={dEnToggleOpenMenu}>
        ENGLISH
      </div>
      <div className={classes.menuVAEspa} onClick={dEsToggleOpenMenu}>
        ESPAÑOL
      </div>
    </animated.div>
  )
}

export default MenuV
