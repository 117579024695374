import React from 'react'
import { Provider } from 'react-redux'
import store from './store'
import AppWithRouter from './AppWithRouter'

export class AppContainer extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AppWithRouter />
      </Provider>
    )
  }
}

export default AppContainer
