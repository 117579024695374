import React, { useCallback } from 'react'
import { useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'

import { getEndMenu, setHeightMenu } from '../../modules/reducerDimensions'

import { useSpring, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-with-gesture'

import Measure from 'react-measure'

import { getSetY } from './MenuSlider'

let getValues = null

const f = p => () => p

let onDown = false
export let moving = false

function disableSelect(event) {
  event.preventDefault()
}

export let positionY = 0
let setE = null
export const setPositionY = p => {
  positionY = p
  if (setE !== null) setE()
}

const MenuScroll = ({ children }) => {
  const dispatch = useDispatch()
  const end = useSelector(getEndMenu)
  const showSlider = end < 0

  const dsetHeightMenu = useCallback(
    contentRect => {
      dispatch(setHeightMenu(contentRect.bounds.height))
    },
    [dispatch]
  )

  getValues = f({ positionY, end })

  const [bind, { delta, down }] = useGesture({
    onMove: e => {
      if (Math.abs(e.delta[1]) > 10) {
        moving = true
      }
    },
    onDown: e => {
      onDown = true
      moving = false
      window.addEventListener('selectstart', disableSelect, { passive: false })
    },
    onUp: e => {
      if (onDown) {
        onDown = false
        window.removeEventListener('selectstart', disableSelect, {
          passive: false
        })
        const values = getValues()
        const p = e.delta[1] + values.positionY
        const end = values.end
        const pos = end > 0 ? 0 : p > 0 ? 0 : p < end ? end : p
        positionY = pos
      }
    }
  })

  //const { y } = useSpring({ y: down ? delta[1] + positionY : positionY })
  const [{ y }, set] = useSpring(() => ({ y: 0 }))
  setE = () => {
    set({ y: !showSlider ? 0 : down ? delta[1] + positionY : positionY })
  }
  set({ y: !showSlider ? 0 : down ? delta[1] + positionY : positionY })

  const onWheel = e => {
    let pos = positionY + -(e.deltaMode === 1 ? e.deltaY * 5 : e.deltaY / 5)

    pos = end > 0 ? 0 : pos > 0 ? 0 : pos < end ? end : pos
    if (pos !== positionY) {
      setPositionY(pos)
    }
  }

  const theme = useTheme()

  const classWidth = showSlider ? 'col-11_sm-12' : 'col-12_sm-12'

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let scroll = true

  if (mq.matches) {
    scroll = false
  }

  return (
    <Measure bounds onResize={dsetHeightMenu}>
      {({ measureRef }) => (
        <div className={classWidth} ref={measureRef}>
          <animated.div
            {...(scroll ? bind() : {})}
            onWheel={onWheel}
            style={{
              transform: interpolate([y], y => {
                if (getSetY !== null) {
                  getSetY(y)
                }

                return `translate3d(0,${y}px,0)`
              })
            }}
          >
            {children}
          </animated.div>
        </div>
      )}
    </Measure>
  )
}

export default MenuScroll
