import React from 'react'
import { useDispatch } from 'react-redux'
import { setIdEstanciaActual, setIdioma } from '../../modules/reducer'

import ImageContainer from '../Images/ImageContainer'
import MenuEstancias from '../MenuEstancias/MenuEstancias'

import { setPositionY } from '../MenuEstancias/MenuScroll'

const Estancia = ({ location, match, history }) => {
  const dispatch = useDispatch()

  dispatch(setIdEstanciaActual(match.params.id))
  dispatch(
    setIdioma(
      typeof match.params.idioma === 'undefined' ? 'es' : match.params.idioma
    )
  )

  setPositionY(0)

  return (
    <>
      <ImageContainer history={history} />
      <MenuEstancias />
    </>
  )
}

export default Estancia
