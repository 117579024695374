import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'

import {
  getEndSliderButtons,
  setWidthSliderButtons,
  setPositionX
} from '../../modules/reducerDimensions'

import Measure from 'react-measure'

import ButtonsSlider from './ButtonsSlider'

import IconArrow from '../Images/IconArrow'

const useStyles = createUseStyles({
  arrowLeft: {
    textAlign: 'center',
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    height: '2em',
    width: '2em'
  },
  arrowRight: {
    textAlign: 'center',
    cursor: 'pointer',
    height: '2em',
    width: '2em'
  }
})

const WrapperButtonsSlider = () => {
  const dispatch = useDispatch()
  const endSliderButtons = useSelector(getEndSliderButtons)

  const dsetPositionX0 = useCallback(e => dispatch(setPositionX(0)), [dispatch])

  const dsetPositionX = useCallback(
    e => dispatch(setPositionX(endSliderButtons)),
    [dispatch, endSliderButtons]
  )

  const dsetWidthSliderButtons = useCallback(
    contentRect => {
      dispatch(setWidthSliderButtons(contentRect.bounds.width))
    },
    [dispatch]
  )

  const classes = useStyles()

  return (
    <div className="grid-noGutter">
      <div className={`${classes.arrowLeft} col-1`} onClick={dsetPositionX0}>
        <IconArrow size="2em" color="white" />
      </div>
      <Measure bounds onResize={dsetWidthSliderButtons}>
        {({ measureRef }) => (
          <div
            ref={measureRef}
            className="col-10"
            style={{ overflow: 'hidden' }}
          >
            <ButtonsSlider />
          </div>
        )}
      </Measure>
      <div className={`${classes.arrowRight} col-1`} onClick={dsetPositionX}>
        <IconArrow size="2em" color="white" />
      </div>
    </div>
  )
}

export default WrapperButtonsSlider
