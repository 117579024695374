import React, { useState, useMemo, useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getOpcionMenu, setVisible } from '../../modules/reducer'

import IconMark from './IconMark'

import MeasureWidth from './MeasureWidth'
import IconEuro from './IconEuro'
import { moving } from './MenuScroll'

const useStyles = createUseStyles({
  wrapperFrame: {
    cursor: 'pointer',
    backgroundColor: ({ visible, theme }) =>
      visible ? theme.backgroundFrame : 'transparent'
  },
  wrapperIcono: { position: 'relative' },
  icono: {
    borderRadius: '50%',
    height: 70,
    width: 70
  },
  title: { fontWeight: 500, whiteSpace: 'normal' },
  subtitle: { fontWeight: 300, whiteSpace: 'normal' },
  precio: { paddingLeft: '1.5em' },
  wrapperTexto: { width: props => props.width - 90 },
  wrapperPrecio: { textAlign: 'right' }
})

const Frame = ({ id, idAcabado, visible, padding }) => {
  const dispatch = useDispatch()

  const getO = useMemo(getOpcionMenu, [])
  const opcion = useSelector(state => getO(state, id))

  const srcIcono = opcion.srcIcono
  const title = opcion.title
  const subtitle = opcion.subtitle
  const precio = opcion.precio

  const [width, setWidth] = useState(0)

  const onClick = useCallback(() => {
    if (!moving) {
      dispatch(setVisible(idAcabado, id))
    }
  }, [dispatch, id, idAcabado])

  const theme = useTheme()
  const classes = useStyles({
    visible,
    width,
    theme
  })

  return (
    <div
      className={`${classes.wrapperFrame} col-12 grid-noGutter`}
      style={{ padding: '5px 0 5px 0' }}
      onClick={onClick}
    >
      <MeasureWidth setSize={setWidth} />
      <div
        className={`${classes.wrapperIcono} grid-middle`}
        style={{ marginLeft: padding }}
      >
        <img className={classes.icono} alt="" src={srcIcono} />
        {visible ? <IconMark size={26} /> : null}
      </div>
      <div className={`${classes.wrapperTexto} grid-middle-noGutter`}>
        <div className="col-7" style={{ paddingLeft: 20 }}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
        <div
          className={`${classes.wrapperPrecio} col-5`}
          style={{ paddingRight: padding / 2 }}
        >
          <IconEuro />
          <span className={classes.precio}>{precio}</span>
        </div>
      </div>
    </div>
  )
}

export default Frame
