import React, { memo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { useSpring, animated } from 'react-spring'
import { useMeasure } from './helpers'

import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'

const useStyles = createUseStyles({
  wrapperTree: {
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    verticalAlign: 'middle',
    fill: 'white'
  },
  cabeceraTree: {
    textTransform: 'uppercase',
    fontWeight: 500,
    cursor: 'pointer'
  }
})

const MinusSquareO = props => (
  <IconContext.Provider value={{ color: '#e0e0e0' }}>
    <FaMinusCircle style={props.style} onClick={props.onClick} />
  </IconContext.Provider>
)

const PlusSquareO = props => (
  <IconContext.Provider value={{ color: '#e0e0e0' }}>
    <FaPlusCircle style={props.style} onClick={props.onClick} />
  </IconContext.Provider>
)

const Tree = memo(
  ({
    children,
    className = '',
    name,
    style,
    styleIcon,
    padding,
    open = false
  }) => {
    const [isOpen, setOpen] = useState(open)
    const [isFirstOpen, setFirstOpen] = useState(true)
    const [bind, { height: viewHeight }] = useMeasure()

    const { height, opacity, transform } = useSpring({
      from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
      to: {
        height: isOpen ? viewHeight : 0,
        opacity: isOpen ? 1 : 0,
        transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`
      }
    })
    const Icon = isOpen ? MinusSquareO : PlusSquareO

    const classes = useStyles({ padding })

    return (
      <div
        className={`${classes.wrapperTree} ${
          className === '' ? '' : className
        } grid-noGutter`}
        style={{ padding: '4px 0px 0px 0px' }}
      >
        <div
          className={`${classes.cabeceraTree} col-12 grid-noGutter-spaceBetween`}
          style={{ paddingLeft: padding, paddingBottom: 10 }}
          onClick={() => {
            setFirstOpen(false)
            setOpen(!isOpen)
          }}
        >
          <span style={{ verticalAlign: 'middle', ...style }}>{name}</span>
          <Icon
            style={{
              width: '1.25em',
              height: '1.25em',
              marginRight: 20,
              verticalAlign: 'middle',
              cursor: 'pointer',
              ...styleIcon
            }}
          />
        </div>
        <animated.div
          className="col-12"
          style={{
            willChange: 'transform, opacity, height',
            padding: 0,
            overflow: 'hidden',
            opacity,
            height: isOpen && isFirstOpen ? 'auto' : height
          }}
        >
          <animated.div style={{ transform }} {...bind}>
            {children}
          </animated.div>
        </animated.div>
      </div>
    )
  }
)

export default Tree
