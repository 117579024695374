import React, { useMemo, useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'
import ButtonJss from '../../ButtonJss'

import { useSelector, useDispatch } from 'react-redux'
import { getEstanciaButton } from '../../modules/reducer'

import { setWidthButtonEstancia } from '../../modules/reducerDimensions'

import { Link } from 'react-router-dom'

import Measure from 'react-measure'

import { moving } from './ButtonsSlider'

const useStyles = theme =>
  createUseStyles({
    buttonEstancia: {
      display: 'inline-flex',
      margin: '0 20px 0 20px',
      textTransform: 'uppercase'
    },
    ...ButtonJss({ className: 'button' }),
    ...ButtonJss({
      className: 'buttonActive',
      opciones: {
        backgroundColor: theme.colorPrincipal,
        borderColor: theme.colorPrincipal
      }
    })
  })

const ButtonEstancia = ({ id, index }) => {
  const dispatch = useDispatch()

  const getE = useMemo(getEstanciaButton, [])
  const estancia = useSelector(state => getE(state, id))
  const path = estancia.path
  const nameButton = estancia.nameButton
  const active = estancia.active

  const dsetWidthButtonEstancia = useCallback(
    contentRect => {
      dispatch(setWidthButtonEstancia(index, contentRect.bounds.width))
    },
    [dispatch, index]
  )

  const theme = useTheme()
  const classes = useStyles(theme)()

  return (
    <Measure bounds onResize={dsetWidthButtonEstancia}>
      {({ measureRef }) => (
        <div ref={measureRef} className={classes.buttonEstancia}>
          <Link
            draggable={false}
            onMouseDown={e => e.preventDefault()}
            className={active ? classes.buttonActive : classes.button}
            to={path}
            onClick={e => {
              if (moving) {
                e.preventDefault()
              }
            }}
          >
            {nameButton}
          </Link>
        </div>
      )}
    </Measure>
  )
}

export default ButtonEstancia
