import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getImagesVisible, getPrevNextEstancias } from '../../modules/reducer'

import { getLoaded } from '../../modules/reducerLoadImages'

import { openInformation } from '../../modules/reducerUI'

import { useSpring } from 'react-spring'
import { useGesture } from 'react-with-gesture'

import IconArrow from './IconArrow'
import IconInformation from './IconInformation'

import ImageEstancia from './ImageEstancia'
import MeasureWidth from './MeasureWidth'

import { default as MenuIdiomasV } from '../Header/MenuV'

import WindowInformation from './WindowInformation'

import ImageLoader from './ImageLoader'

let getValues = null
const f = p => () => p

const useStyles = theme =>
  createUseStyles({
    imageContainer: {
      position: 'relative',
      backgroundColor: ({ theme }) => theme.backgroundImages,
      zIndex: 1,
      height: props => props.height
    },
    [`@media screen and (max-width: ${theme.mediaMovil})`]: {
      imageContainer: {
        position: 'fixed',
        width: '100%'
      }
    },
    imageContainerIconPrev: {
      position: 'absolute',
      textAlign: 'center',
      cursor: 'pointer',
      width: '4em',
      height: '4em',
      top: props => props.topIcon,
      transform: 'rotate(180deg)',
      left: '10px'
    },
    imageContainerIconNext: {
      position: 'absolute',
      textAlign: 'center',
      cursor: 'pointer',
      width: '4em',
      height: '4em',
      top: props => props.topIcon,
      right: '10px'
    },
    imageContainerInformation: {
      position: 'absolute',
      bottom: '15px',
      right: '25px',
      cursor: 'pointer'
    }
  })

const ImageContainer = ({ history }) => {
  const dispatch = useDispatch()

  const images = useSelector(getImagesVisible)
  const loaded = useSelector(getLoaded)

  const sizeContainer = useSelector(
    state => state.dimensions.sizeImageContainer
  )

  const prevNext = useSelector(getPrevNextEstancias)
  const estanciaPrev = prevNext.prev
  const estanciaNext = prevNext.next

  const dopenInformation = useCallback(() => dispatch(openInformation()), [
    dispatch
  ])

  getValues = f({ estanciaPrev, estanciaNext })

  const [bind, { delta, down }] = useGesture({
    onUp: e => {
      const values = getValues()
      if (Math.abs(e.delta[0]) < 125) return
      if (e.delta[0] > 0) {
        if (values.estanciaPrev !== null) {
          history.push(values.estanciaPrev.path)
        }
      } else if (e.delta[0] < 0) {
        if (values.estanciaNext !== null) {
          history.push(values.estanciaNext.path)
        }
      }
    }
  })
  const { x } = useSpring({
    x: down ? delta[0] : 0
  })

  const theme = useTheme()

  let height = null
  let topIcon = ''
  let imagenPrev = null
  let imagenNext = null
  let menuIdiomas = null

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  if (mq.matches) {
    height = 257
    topIcon = `calc(${height / 2}px - 2em)`

    imagenPrev = estanciaPrev ? (
      <ImageEstancia {...estanciaPrev} visible prev={true} next={false} x={x} />
    ) : null

    imagenNext = estanciaNext ? (
      <ImageEstancia {...estanciaNext} visible prev={false} next={true} x={x} />
    ) : null

    menuIdiomas = <MenuIdiomasV history={history} />
  } else {
    topIcon = `calc(${sizeContainer.height / 2}px - 2em)`
  }

  const classes = useStyles(theme)({ height, topIcon, theme })

  return (
    <div
      {...bind()}
      className={`${classes.imageContainer} col-9_sm-12_md-8 grid-noGutter`}
    >
      <MeasureWidth />
      {menuIdiomas}
      <WindowInformation />
      {imagenPrev}
      {loaded ? (
        images.map(image => (
          <ImageEstancia
            key={image.id}
            src={image.src}
            width={image.width}
            height={image.height}
            visible={image.visible}
            prev={false}
            next={false}
            x={x}
          />
        ))
      ) : (
        <ImageLoader />
      )}
      {imagenNext}
      {estanciaPrev !== null ? (
        <div
          className={classes.imageContainerIconPrev}
          onClick={e => history.push(estanciaPrev.path)}
        >
          <IconArrow size="4em" color={theme.colorPrincipal} />
        </div>
      ) : null}
      {estanciaNext !== null ? (
        <div
          className={classes.imageContainerIconNext}
          onClick={e => history.push(estanciaNext.path)}
        >
          <IconArrow size="4em" color={theme.colorPrincipal} />
        </div>
      ) : null}
      <div
        className={`${classes.imageContainerInformation} col-bottom`}
        onClick={dopenInformation}
      >
        <IconInformation size="1.5em" color={theme.colorPrincipal} />
      </div>
    </div>
  )
}

export default ImageContainer
