import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { toggleOpenMenu } from '../../modules/reducerUI'

import { setHeightHeader } from '../../modules/reducerDimensions'

import Measure from 'react-measure'

//import IconGlobe from './IconGlobe'
import IconGps from './IconGps'
import IconHamburguer from './IconHamburguer'
import MenuIdiomasH from './MenuIdiomasH'

const useStyles = theme =>
  createUseStyles({
    header: {
      backgroundColor: ({ theme }) => theme.backgroundHeader,
      minHeight: '10%',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: ({theme}) => theme.shadowHeader ? '0 0 6px #ccc':'none'
    },
    [`@media screen and (max-width: ${theme.mediaMovil})`]: {
      header: {
        position: 'fixed',
        width: '100%',
        zIndex: 2
      }
    },
    [`@media screen and (max-width: ${theme.mediaScroll})`]: {
      header: {
        minHeight: '81px'
      }
    },
    headerLogo: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: '70px',
      alignSelf: 'center',
      backgroundImage: props =>
        props.idioma === 'en' ? 'url(logo-ingles.png)' : 'url(logo.png)'
    },
    iconHamburguer: {
      height: '1.5em',
      width: '1.5em',
      right: props => props.right,
      cursor: 'pointer',
      alignSelf: 'center',
      position: 'absolute'
    },
    iconGps: {
      height: '2.4em',
      width: '2.4em',
      cursor: 'pointer',
      alignSelf: 'center',
      position: 'absolute',
      right: props => props.right //`calc(${props.right} + 10px + 2.4em)`
    }
  })

const Header = ({ history }) => {
  const dispatch = useDispatch()
  const idioma = useSelector(state => state.reducer.idioma)
  const dtoggleOpenMenu = useCallback(() => dispatch(toggleOpenMenu()), [
    dispatch
  ])
  const dsetHeightHeader = useCallback(
    contentRect => {
      dispatch(setHeightHeader(contentRect.bounds.height))
    },
    [dispatch]
  )

  const theme = useTheme()

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let menuIdiomas = null
  let right = '1.5em' //'0.75em'

  if (!mq.matches) {
    menuIdiomas = <MenuIdiomasH history={history} />
    right = '1.3em'
  }

  const classes = useStyles(theme)({ right, idioma, theme })

  return (
    <Measure bounds onResize={dsetHeightHeader}>
      {({ measureRef }) => (
        <div
          ref={measureRef}
          className={`${classes.header}  col-12 grid-spaceBetween-noGutter`}
        >
          <div className="col-3_sm-2" />

          <div className={`${classes.headerLogo} col-3_sm-6`} />
          <div className="col-3_sm-2" />
          {menuIdiomas}
          {mq.matches ? (
            <div className={classes.iconHamburguer} onClick={dtoggleOpenMenu}>
              <IconHamburguer size="1.5em" color={theme.iconnHeaderColor} />
            </div>
          ) : (
            <>
              <div className={classes.iconGps} onClick={dtoggleOpenMenu}>
                <IconGps size="2.3em" color={theme.iconnHeaderColor} />
              </div>
            </>
          )}
        </div>
      )}
    </Measure>
  )
}

export default Header
