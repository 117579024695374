//Actions
export const TOGGLE_GENERATE_PDF = 'TOGGLE_GENERATE_PDF'
export const TOGGLE_PDF_LOADING = 'TOGGLE_PDF_LOADING'

const initialState = {
  generatePdf: false,
  pdfLoading: false,
  srcLogoPdf: 'images/logo_pdf/logo_pdf_1.png',
  nombrePdf: 'archivo'
}

export const toggleGeneratePdf = () => ({
  type: TOGGLE_GENERATE_PDF
})

export const togglePdfLoading = () => ({
  type: TOGGLE_PDF_LOADING
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_GENERATE_PDF:
      return {
        ...state,
        generatePdf: !state.generatePdf
      }
    case TOGGLE_PDF_LOADING:
      return {
        ...state,
        pdfLoading: !state.pdfLoading
      }
    default:
      return state
  }
}
