import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'
import ButtonJss from '../../ButtonJss'

import { useSelector, useDispatch } from 'react-redux'
import {
  getTotal,
  getOpcionesSeleccionadas,
  setVisibleDefault,
  getOpcionesSeleccionadasPdf
} from '../../modules/reducer'

import { toggleGeneratePdf, togglePdfLoading } from '../../modules/reducerPDF'

import { toggleResumen } from '../../modules/reducerUI'

import {
  getTextoIva,
  getTextoGenerarPDF,
  getTextoCerrarSeleccionadas,
  getTextoHeaderPdf
} from '../../modules/getTextos'

import { useTransition, animated } from 'react-spring'

import { IoIosCloseCircleOutline } from 'react-icons/io'
import { MdClear } from 'react-icons/md'
import { IconContext } from 'react-icons'

import MyPdfDownloadLink from './MyPdfDownloadLink'

import Pdf from './Pdf'

const useStyles = theme =>
  createUseStyles({
    resumen: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.6)',
      zIndex: 2,
      display: props => (props.open ? 'block' : 'none')
    },
    modalMain: {
      position: 'relative',
      background: 'white',
      width: '80%',
      height: '90%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflowY: 'auto'
    },
    closeResumen: {
      right: '10px',
      top: '10px',
      position: 'absolute',
      cursor: 'pointer'
    },
    containerResumen: {
      position: 'relative',
      height: props => props.opcionesLength * 120 + 85
    },
    logo: { height: 70 },
    rowContainerResumen: {
      position: 'absolute',
      height: '100px',
      width: '100%'
    },
    rowIconoContainerResumen: {
      position: 'absolute',
      left: '10px'
    },
    rowIconoResumen: {
      borderRadius: '50%',
      height: '70px',
      width: '70px'
    },
    rowTextoContainerResumen: {
      position: 'absolute',
      left: '90px',
      width: 'calc(100% - 105px - 2em)'
    },
    acabadoTitleResumen: {
      color: '#04a4cc',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    titleResumen: {
      fontWeight: 500
    },
    subtitleResumen: {
      fontWeight: 300
    },
    rowCloseContainer: {
      position: 'absolute',
      right: '10px',
      cursor: 'pointer'
    },
    totalResumen: {
      fontSize: '1.17em',
      fontWeight: 'bold'
    },
    totalNumeroResumen: {
      paddingLeft: '5px'
    },
    ivaResumen: {
      fontSize: '0.8em'
    },
    [`@media screen and (max-width: ${theme.mediaScroll})`]: {
      titleResumen: {
        overflowX: 'auto',
        whiteSpace: 'nowrap'
      },
      subtitleResumen: {
        overflowX: 'auto',
        whiteSpace: 'nowrap'
      },
      acabadoTitleResumen: {
        overflowX: 'auto',
        whiteSpace: 'nowrap'
      },
      modalMain: {
        width: '100%',
        height: '100%'
      }
    },
    ...ButtonJss({
      className: 'buttonCerrar',
      opciones: {
        margin: '20px 0',
        display: 'block',
        backgroundColor: theme.textColor
      },
      opcionesHover: {
        backgroundColor: theme.textColor,
        borderColor: theme.textColor
      }
    }),
    ...ButtonJss({
      className: 'buttonPdf',
      opciones: {
        backgroundColor: theme.colorPrincipal,
        margin: '20px 0',
        display: 'block'
      }
    })
  })

const OpcionesSeleccionadas = () => {
  const dispatch = useDispatch()
  const total = useSelector(getTotal)
  const opciones = useSelector(getOpcionesSeleccionadas)
  const opcionesPdf = useSelector(getOpcionesSeleccionadasPdf)
  const open = useSelector(state => state.ui.openResumen)
  const generatePdf = useSelector(state => state.pdf.generatePdf)
  const srcLogo = useSelector(state => state.ui.srcLogoResumen)
  const srcLogoPdf = useSelector(state => state.pdf.srcLogoPdf)
  const nombrePdf = useSelector(state => state.pdf.nombrePdf)
  const textoIva = useSelector(getTextoIva)
  const textoGenerarPDF = useSelector(getTextoGenerarPDF)
  const textoCerrarSeleccionadas = useSelector(getTextoCerrarSeleccionadas)
  const textoHeaderPdf = useSelector(getTextoHeaderPdf)

  const dsetVisibleDefault = useCallback(
    (idAcabado, id) => dispatch(setVisibleDefault(idAcabado, id)),
    [dispatch]
  )

  const dtoggleResumen = useCallback(() => dispatch(toggleResumen()), [
    dispatch
  ])

  const dgeneratePdf = useCallback(() => {
    dispatch(togglePdfLoading())
    dispatch(toggleGeneratePdf())
  }, [dispatch])

  const transitions = useTransition(opciones, item => item.id, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' }
  })

  const theme = useTheme()
  //const classes = useStyles({ open, opcionesLength: opciones.length })
  const classes = useStyles(theme)({ open, opcionesLength: opciones.length })

  return (
    <div className={classes.resumen}>
      <section className={classes.modalMain}>
        <div className={classes.closeResumen} onClick={dtoggleResumen}>
          <IconContext.Provider
            value={{
              color: theme.textColor,
              size: '2em'
            }}
          >
            <MdClear />
          </IconContext.Provider>
        </div>

        <div
          className={`${classes.containerResumen} grid-center-noGutter`}
          style={{ marginTop: 40 }}
        >
          <img className={classes.logo} src={srcLogo} alt="" />
          {transitions.map(({ item, props, key }) => {
            return (
              <animated.div
                key={key}
                className={`${classes.rowContainerResumen} col-10_sm-12 grid-middle-noGutter`}
                style={{
                  top: 120 * item.index + 85,
                  opacity: props.opacity,
                  transform: props.transform
                }}
              >
                <div className={classes.rowIconoContainerResumen}>
                  <img
                    className={classes.rowIconoResumen}
                    src={item.srcIcono}
                    alt=""
                  />
                </div>
                <div className={classes.rowTextoContainerResumen}>
                  <div className={classes.acabadoTitleResumen}>
                    {item.acabadoTitle}
                  </div>
                  <div className={classes.titleResumen}>{item.title}</div>
                  <div className={classes.subtitleResumen}>{item.subtitle}</div>
                  <div>{`${item.precio}€`}</div>
                </div>
                {!item.default ? (
                  <div className={classes.rowCloseContainer}>
                    <IconContext.Provider
                      value={{ color: theme.textColor, size: '2em' }}
                    >
                      <IoIosCloseCircleOutline
                        onClick={e =>
                          dsetVisibleDefault(item.idAcabado, item.id)
                        }
                      />
                    </IconContext.Provider>
                  </div>
                ) : null}
              </animated.div>
            )
          })}
        </div>
        <div className="grid-center-noGutter">
          <div className="col-10_sm12" style={{ marginBottom: 10 }}>
            <span className={classes.totalResumen}>TOTAL:</span>
            <span className={classes.totalNumeroResumen}>{total}€</span>
            <div className={classes.ivaResumen}>{textoIva}</div>
          </div>
          <div className="col-10_sm12 grid-center-noGutter">
            <div className="col-9_sm-12">
              <div className={classes.buttonPdf} onClick={dgeneratePdf}>
                {textoGenerarPDF}
              </div>

              {generatePdf ? (
                <div>
                  <MyPdfDownloadLink
                    document={
                      <Pdf
                        opciones={opcionesPdf}
                        total={total}
                        textoIva={textoIva}
                        srcLogo={srcLogoPdf}
                        textoHeaderPdf={textoHeaderPdf}
                      />
                    }
                    idEnlace="download-pdf"
                  />
                </div>
              ) : null}

              <a
                id="download-pdf"
                href="http://www.google.es"
                style={{ display: 'none' }}
                download={`${nombrePdf}.pdf`}
              >
                a
              </a>

              <div className={classes.buttonCerrar} onClick={dtoggleResumen}>
                {textoCerrarSeleccionadas}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OpcionesSeleccionadas
