import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getMenu } from '../../modules/reducer'
import { toggleOpenMenu } from '../../modules/reducerUI'

import { useSpring, animated } from 'react-spring'

const useStyles = createUseStyles({
  menuH: {
    alignSelf: 'center',
    position: 'absolute',
    color: ({ theme }) => theme.menuIdiomasColor,
    fontSize: '0.8em'
  },
  menuHAIngles: {
    display: 'inline',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: props => (props.idioma === 'en' ? 500 : 300)
  },
  menuHAEspa: {
    display: 'inline',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: props => (props.idioma === 'es' ? 500 : 300)
  }
})

const MenuIdiomasH = ({ history }) => {
  const dispatch = useDispatch()
  const menu = useSelector(getMenu)
  const idioma = menu.idioma
  const path = menu.path
  const visible = useSelector(state => state.ui.openMenu)

  const dEnToggleOpenMenu = useCallback(
    e => {
      history.push(`/en/${path}`)
      dispatch(toggleOpenMenu())
    },
    [dispatch, history, path]
  )

  const dEsToggleOpenMenu = useCallback(
    e => {
      history.push(`/es/${path}`)
      dispatch(toggleOpenMenu())
    },
    [dispatch, history, path]
  )

  const { opacity, right } = useSpring({
    opacity: visible ? 1 : 0,
    right: visible ? '5em' : '1em'
  })

  const theme = useTheme()
  const classes = useStyles({ idioma, theme })

  return (
    <animated.div
      className={classes.menuH}
      style={{
        opacity,
        right
      }}
    >
      <div className={classes.menuHAIngles} onClick={dEnToggleOpenMenu}>
        ENGLISH
      </div>
      |
      <div className={classes.menuHAEspa} onClick={dEsToggleOpenMenu}>
        ESPAÑOL
      </div>
    </animated.div>
  )
}

export default MenuIdiomasH
