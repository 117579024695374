import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { setSizeImageContainerHeight } from '../../modules/reducerDimensions'

import Measure from 'react-measure'

const MeasureHeight = () => {
  const dispatch = useDispatch()

  const dsetSizeImageContainerHeight = useCallback(
    contentRect => {
      dispatch(setSizeImageContainerHeight(contentRect.bounds.height))
    },
    [dispatch]
  )

  return (
    <Measure bounds onResize={dsetSizeImageContainerHeight}>
      {({ measureRef }) => (
        <div ref={measureRef} style={{ height: '100%', width: 0 }} />
      )}
    </Measure>
  )
}

export default MeasureHeight
