import { createSelector } from 'reselect'

import { getTextoIdioma } from './utils'

const idiomaSelector = state => state.reducer.idioma
const textosSelector = state => state.reducer.textos

export const getTextoInformacion = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'informacion', idioma)
)

export const getTextoCerrarInformacion = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'cerrar', idioma)
)

export const getTextoCerrarSeleccionadas = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'cerrar', idioma)
)

export const getTextoIva = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'iva', idioma)
)

export const getTextoBotonResumen = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'botonResumen', idioma)
)

export const getTextoBotonReset = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'botonReset', idioma)
)

export const getTextoGenerarPDF = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'generarPDF', idioma)
)

export const getTextoGuardarConfiguraciones = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'guardarConfiguraciones', idioma)
)

export const getTextoLegal = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'legal', idioma)
)

export const getTextoCerrarLegal = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'cerrar', idioma)
)

export const getTextoHeaderPdf = createSelector(
  [idiomaSelector, textosSelector],
  (idioma, textos) => getTextoIdioma(textos, 'headerPdf', idioma)
)
