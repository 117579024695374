import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getAcabados, getEstanciaActualTitle } from '../../modules/reducer'

import { getEndMenu, setHeightViewport } from '../../modules/reducerDimensions'

import Acabado from './Acabado'
import MenuSlider from './MenuSlider'

import MenuScroll from './MenuScroll'
import MenuTotales from './MenuTotales'

import MeasureHeight from './MeasureHeight'

import { setPositionY } from './MenuScroll'

const useStyles = theme =>
  createUseStyles({
    slider: { height: '100%', display: props => props.display },
    menuViewport: {
      flexGrow: '1 !important',
      flexShrink: '1 !important',
      flexBasis: '10px !important',
      overflowY: 'hidden'
    },
    [`@media screen and (max-width: ${theme.mediaScroll})`]: {
      menuViewport: {
        flex: 'none !important',
        overflowY: 'visible'
      }
    }
  })

const MenuEstancias = () => {
  const dispatch = useDispatch()
  const endMenu = useSelector(getEndMenu)
  const showSlider = endMenu < 0

  const estanciaTitle = useSelector(getEstanciaActualTitle)
  const acabados = useSelector(getAcabados)

  const dsetHeightViewport = useCallback(
    height => dispatch(setHeightViewport(height)),
    [dispatch]
  )

  const theme = useTheme()

  const padding = 20

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let ocultSlider = false

  if (mq.matches) {
    ocultSlider = true
  }

  const mq1 = window.matchMedia(`(max-width: ${theme.mediaMovil})`)

  let marginTop = 0

  if (mq1.matches) {
    marginTop = 257
  }

  let display = 'block'
  let visibleSlider = true

  if (ocultSlider) {
    display = 'none'
    visibleSlider = false
    setPositionY(0)
  } else if (showSlider) {
    display = 'block'
    visibleSlider = true
  } else {
    display = 'none'
    visibleSlider = false
    setPositionY(0)
  }

  const classes = useStyles(theme)({ display })

  return (
    <div
      className="col-3_sm-12_md-4 grid-column-noGutter"
      style={{ marginTop }}
    >
      <div className="col-12" style={{ paddingLeft: padding }}>
        <h1>{estanciaTitle}</h1>
      </div>
      <div className={`${classes.menuViewport} col-12 grid-noGutter`}>
        <MeasureHeight setSize={dsetHeightViewport} />

        <MenuScroll>
          {acabados.map(idAcabado => (
            <Acabado key={idAcabado} id={idAcabado} padding={padding} />
          ))}
        </MenuScroll>

        <div className={`${classes.slider} col-1 sm-hidden`}>
          <MenuSlider visible={visibleSlider} />
        </div>
      </div>

      <MenuTotales padding={padding} />
    </div>
  )
}

export default MenuEstancias
