import React from 'react'
import { FaEuroSign, FaCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import theme from '../../Theme'

const IconEuro = () => {
  return (
    <span style={{ position: 'relative' }}>
      <IconContext.Provider
        value={{ color: theme.colorPrincipal, size: '1em' }}
      >
        <FaCircle style={{ position: 'absolute', left: 0, top: '0.2em' }} />
      </IconContext.Provider>
      <IconContext.Provider value={{ color: 'white', size: '0.7em' }}>
        <FaEuroSign
          style={{ position: 'absolute', left: '0.15em', top: '0.3em' }}
        />
      </IconContext.Provider>
    </span>
  )
}

export default IconEuro
