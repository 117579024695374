import React, { useState, useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'
import ButtonJss from '../../ButtonJss'

import { useSelector, useDispatch } from 'react-redux'
import { getSubtotal, getTotal, setDefaults } from '../../modules/reducer'

import { toggleResumen } from '../../modules/reducerUI'

import {
  getTextoIva,
  getTextoBotonResumen,
  getTextoBotonReset
} from '../../modules/getTextos'

import IconEuro from './IconEuro'
import IconReplay from './IconReplay'

const useStyles = theme =>
  createUseStyles({
    wrapper: {
      height: props => props.height
    },
    linea: {
      backgroundColor: 'black',
      height: 1,
      marginTop: 30
    },
    wrapperSubtotal: { paddingTop: 30 },
    subtotal: { paddingLeft: 5 },
    wrapperTitleTotal: { paddingTop: 20 },
    titleTotal: { margin: 0 },
    iva: { fontSize: '0.8em' },
    total: { paddingLeft: '1em', fontSize: '1.2em' },
    wrapperResumen: { marginTop: 20 },
    ...ButtonJss({
      className: 'button',
      opciones: { backgroundColor: theme.colorPrincipal }
    }),
    ...ButtonJss({
      className: 'buttonReset',
      opciones: { backgroundColor: theme.colorPrincipal, marginLeft: 10 }
    })
  })

const MenuTotales = ({ padding }) => {
  const dispatch = useDispatch()
  const subtotal = useSelector(getSubtotal)
  const total = useSelector(getTotal)
  const textoIva = useSelector(getTextoIva)
  const textoBotonResumen = useSelector(getTextoBotonResumen)
  const textoBotonReset = useSelector(getTextoBotonReset)

  const dtoggleResumen = useCallback(e => dispatch(toggleResumen()), [dispatch])

  const [solicitudReset, setSolicitudReset] = useState(false)

  const dsetDefaults = useCallback(
    e => {
      setSolicitudReset(!solicitudReset)
      dispatch(setDefaults())
    },
    [dispatch, setSolicitudReset, solicitudReset]
  )

  const theme = useTheme()

  const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let height = '40%'
  if (mq.matches) {
    height = '260px' //'20%'
  }

  const classes = useStyles(theme)({ height })

  return (
    <div
      className={`${classes.wrapper} col-12`}
      style={{ padding: `0 ${padding}px ${padding}px ${padding}px` }}
    >
      <div className={classes.linea} />
      <div className={classes.wrapperSubtotal}>
        <span>Subtotal:</span>
        <span className={classes.subtotal}>{subtotal}</span>
        <span>€</span>
      </div>
      <div className={classes.wrapperTitleTotal}>
        <h3 className={classes.titleTotal}>Total</h3>
      </div>
      <div>
        <IconEuro />
        <span className={classes.total}>{total}</span>
      </div>
      <div className={classes.iva}>{textoIva}</div>
      <div className={classes.wrapperResumen}>
        <div className={classes.button} onClick={dtoggleResumen}>
          {textoBotonResumen}
        </div>

        {solicitudReset ? (
          <div className={classes.buttonReset} onClick={dsetDefaults}>
            {textoBotonReset}
          </div>
        ) : (
          <IconReplay
            onClick={e => {
              setSolicitudReset(!solicitudReset)
            }}
            size={'2em'}
          />
        )}
      </div>
    </div>
  )
}

export default MenuTotales
