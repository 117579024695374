//import settings from '../settings.json'

import initialState from './initialState'

import { createSelector } from 'reselect'

import { number_format } from './number_format'

import { searchId, updateId, getTextoIdioma } from './utils'

//import pathIntranet from '../ruta'

//Actions

export const SET_VISIBLE = 'SET_VISIBLE'

export const SET_VISIBLE_DEFAULT = 'SET_VISIBLE_DEFAULT'
export const SET_DEFAULTS = 'SET_DEFAULTS'
export const SET_ID_ESTANCIA_ACTUAL = 'SET_ID_ESTANCIA_ACTUAL'

export const SET_URL = 'SET_URL'
export const SET_IDIOMA = 'SET_IDIOMA'

export const SET_PRECIO_BASE = 'SET_PRECIO_BASE'

export const setVisible = (acabado, opcion) => ({
  type: SET_VISIBLE,
  acabado,
  opcion
})

export const setVisibleDefault = (idAcabado, idOpcion) => ({
  type: SET_VISIBLE_DEFAULT,
  idAcabado,
  idOpcion
})

export const setDefaults = () => ({
  type: SET_DEFAULTS
})

export const setIdEstanciaActual = path => ({
  type: SET_ID_ESTANCIA_ACTUAL,
  path
})

export const setPrecioBase = precio => ({
  type: SET_PRECIO_BASE,
  precio
})

export const setUrl = url => ({
  type: SET_URL,
  url
})

export const setIdioma = idioma => ({
  type: SET_IDIOMA,
  idioma
})

const searchPath = (estancias, path) => {
  for (let i = 0, iLen = estancias.length; i < iLen; i++) {
    if (estancias[i].path === path) {
      return estancias[i].id
    }
  }

  return null
}

/*
const setEstanciasState = (state, action) => {
  const widthButtonEstancia = action.ordenEstancias.map(estancia => 0)

  const estancias = action.ordenEstancias.map(idEstancia => {
    const estancia = searchId(action.estancias, idEstancia)

    const size = estancia.size.split('_')
    const path = estancia.path === '' ? estancia.id : estancia.path
    const acabados = estancia.acabados.split(',')
    const nameButton =
      estancia.nameButton === '' ? estancia.title : estancia.nameButton
    const nameButtonIngles =
      estancia.nameButtonIngles === ''
        ? estancia.titleIngles
        : estancia.nameButtonIngles

    return {
      id: estancia.id,
      nameButton,
      nameButtonIngles,
      title: estancia.title,
      titleIngles: estancia.titleIngles,
      src: `${pathIntranet}${estancia.src}`,
      width: size[0],
      height: size[1],
      acabados,
      path,
      pdfIconosLinea: estancia.pdficonoslinea * 1
    }
  })

  const srcEstanciasLoaded = estancias.map(estancia => ({
    id: estancia.id,
    loaded: false
  }))

  return {
    estancias,
    widthButtonEstancia,
    idEstanciaActual: estancias[0].id,
    srcEstanciasLoaded
  }
}

const setAcabadosState = (state, action) => {
  const acabados = action.acabados.map(acabado => {
    const opciones = acabado.opciones.split(',')

    return {
      id: acabado.id,
      title: acabado.title,
      titleIngles: acabado.titleIngles,
      multiple: acabado.multiple === '1',
      opciones,
      parentAcabado: ''
    }
  })

  return {
    acabados
  }
}

const setOpcionesState = (state, action) => {
  const opciones = action.opciones.map(opcion => {
    const size = opcion.size.split('_')
    const srcIconoPdf =
      opcion.srcIconoPdf === ''
        ? []
        : opcion.srcIconoPdf.split(',').map(src => `${pathIntranet}${src}`)

    return {
      id: opcion.id,
      title: opcion.title,
      titleIngles: opcion.titleIngles,
      subtitle: opcion.subtitle,
      subtitleIngles: opcion.subtitleIngles,
      srcIcono: `${pathIntranet}${opcion.srcIcono}`,
      precio: opcion.precio * 1,
      src: `${pathIntranet}${opcion.src}`,
      width: size[0],
      height: size[1],
      srcIconoPdf,
      subOpciones: []
    }
  })

  return {
    opciones
  }
}
*/

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VISIBLE:
      const { multiple } = searchId(state.acabados, action.acabado)

      let opcionesAcabado = []

      if (multiple) {
        opcionesAcabado = [
          ...searchId(state.opcionesSeleccionadas, action.acabado).opciones
        ]

        const index = opcionesAcabado.indexOf(action.opcion)

        if (index === -1) {
          opcionesAcabado.push(action.opcion)
        } else {
          opcionesAcabado.splice(index, 1)
        }
      } else {
        opcionesAcabado = [action.opcion]
      }

      const opcionesSeleccionadas6 = updateId(
        state.opcionesSeleccionadas,
        action.acabado,
        'opciones',
        opcionesAcabado
      )

      return {
        ...state,
        opcionesSeleccionadas: opcionesSeleccionadas6
      }

    case SET_VISIBLE_DEFAULT:
      const acabado = searchId(state.acabados, action.idAcabado)

      let opcionesSeleccionadas1 = []

      if (acabado.multiple) {
        let { opciones: opS } = searchId(
          state.opcionesSeleccionadas,
          action.idAcabado
        )

        const opS1 = []

        for (let i = 0, iLen = opS.length; i < iLen; i++) {
          if (opS[i] !== action.idOpcion) {
            opS1.push(opS[i])
          }
        }

        opcionesSeleccionadas1 = updateId(
          state.opcionesSeleccionadas,
          action.idAcabado,
          'opciones',
          opS1
        )
      } else {
        opcionesSeleccionadas1 = updateId(
          state.opcionesSeleccionadas,
          action.idAcabado,
          'opciones',
          acabado.opciones.length > 0 ? [acabado.opciones[0]] : []
        )
      }

      return {
        ...state,
        opcionesSeleccionadas: opcionesSeleccionadas1
      }
    case SET_DEFAULTS:
      const opcionesSeleccionadas2 = state.acabados.map(acabado => {
        return {
          id: acabado.id,
          opciones: acabado.multiple ? [] : [acabado.opciones[0]]
        }
      })

      return {
        ...state,
        opcionesSeleccionadas: opcionesSeleccionadas2
      }
    case SET_ID_ESTANCIA_ACTUAL:
      let idEstanciaActual1 = state.estancias[0].id
      if (typeof action.path !== 'undefined') {
        idEstanciaActual1 = searchPath(state.estancias, action.path)
        if (idEstanciaActual1 === null) {
          idEstanciaActual1 = state.estancias[0].id
        }
      }

      return {
        ...state,
        idEstanciaActual: idEstanciaActual1
      }
    case SET_URL:
      return {
        ...state,
        url: action.url
      }
    case SET_PRECIO_BASE:
      return {
        ...state,
        precioBase: action.precio
      }
    case SET_IDIOMA:
      return {
        ...state,
        idioma: action.idioma
      }
    default:
      return state
  }
}

const estanciasSelector = state => state.reducer.estancias
const acabadosSelector = state => state.reducer.acabados
const opcionesSelector = state => state.reducer.opciones
const idEstanciaActualSelector = state => state.reducer.idEstanciaActual
const opcionesSeleccionadasSelector = state =>
  state.reducer.opcionesSeleccionadas
const precioBaseSelector = state => state.reducer.precioBase
const idiomaSelector = state => state.reducer.idioma

export const getEstancias = createSelector(
  [estanciasSelector],
  estancias => estancias.map(estancia => estancia.id)
)

export const getEstanciaButton = () =>
  createSelector(
    [
      estanciasSelector,
      idiomaSelector,
      idEstanciaActualSelector,
      (_, idEstancia) => idEstancia
    ],
    (estancias, idioma, idEstanciaActual, idEstancia) => {
      const estancia = searchId(estancias, idEstancia)

      return {
        path: `/${idioma}/${estancia.path}`,
        nameButton: getTextoIdioma(estancia, 'nameButton', idioma),
        active: idEstancia === idEstanciaActual
      }
    }
  )

export const getMenu = createSelector(
  [estanciasSelector, idiomaSelector, idEstanciaActualSelector],
  (estancias, idioma, idEstanciaActual) => {
    if (typeof estancias === 'undefined') return { idioma: '', path: '' }

    const { path } = searchId(estancias, idEstanciaActual)

    return { idioma, path }
  }
)

const getSubtotalAcabados = (
  idAcabados,
  acabados,
  opciones,
  opcionesSeleccionadas
) =>
  idAcabados.reduce((result, idAcabado) => {
    const acabado = searchId(acabados, idAcabado)

    const idOpciones =
      acabado.parentAcabado !== ''
        ? getSubopcionesValidas(
            idAcabado,
            acabado.parentAcabado,
            opciones,
            opcionesSeleccionadas
          ).subopcionesSeleccionadas
        : searchId(opcionesSeleccionadas, idAcabado).opciones

    return (
      result +
      idOpciones.reduce((result1, idOpcion) => {
        const { precio } = searchId(opciones, idOpcion)
        return result1 + precio
      }, 0)
    )
  }, 0)

export const getSubtotal = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idEstanciaActualSelector
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idEstanciaActual) => {
    const { acabados: idAcabados } = searchId(estancias, idEstanciaActual)

    const precio = getSubtotalAcabados(
      idAcabados,
      acabados,
      opciones,
      opcionesSeleccionadas
    )

    return number_format(precio, 2, ',', '.')
  }
)

export const getTotal = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    precioBaseSelector
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, precioBase) => {
    const precio = estancias.reduce(
      (result1, estancia) =>
        result1 +
        getSubtotalAcabados(
          estancia.acabados,
          acabados,
          opciones,
          opcionesSeleccionadas
        ),
      precioBase
    )

    return number_format(precio, 2, ',', '.')
  }
)

const getSubopcionesValidas = (
  idAcabado,
  idParentAcabado,
  opciones,
  opcionesSeleccionadas
) => {
  const { opciones: idOpcionesSeleccionadasParent } = searchId(
    opcionesSeleccionadas,
    idParentAcabado
  )

  const subopcionesValidas = idOpcionesSeleccionadasParent.reduce(
    (result, idOpcionParent) => {
      const { subOpciones } = searchId(opciones, idOpcionParent)

      return result.concat(subOpciones)
    },
    []
  )

  const subopcionesSeleccionadas = searchId(
    opcionesSeleccionadas,
    idAcabado
  ).opciones.reduce((result, op) => {
    if (subopcionesValidas.indexOf(op) !== -1) {
      result.push(op)
    }

    return result
  }, [])

  return {
    subopcionesValidas,
    subopcionesSeleccionadas
  }
}

export const getAcabado = () =>
  createSelector(
    [
      acabadosSelector,
      opcionesSeleccionadasSelector,
      idiomaSelector,
      opcionesSelector,
      (_, idAcabado) => idAcabado
    ],
    (acabados, opcionesSeleccionadas, idioma, opciones, idAcabado) => {
      const acabado = searchId(acabados, idAcabado)

      let opciones1 = []
      let opcionesSeleccionadas1 = []
      if (acabado.parentAcabado !== '') {
        const {
          subopcionesValidas,
          subopcionesSeleccionadas
        } = getSubopcionesValidas(
          acabado.id,
          acabado.parentAcabado,
          opciones,
          opcionesSeleccionadas
        )

        opciones1 = subopcionesValidas
        opcionesSeleccionadas1 = subopcionesSeleccionadas
      } else {
        opciones1 = acabado.opciones
        opcionesSeleccionadas1 = searchId(opcionesSeleccionadas, idAcabado)
          .opciones
      }

      return {
        ...acabado,
        id: acabado.id,
        title: getTextoIdioma(acabado, 'title', idioma),
        opciones: opciones1,
        opcionesSeleccionadas: opcionesSeleccionadas1
      }
    }
  )

export const getOpcionMenu = () =>
  createSelector(
    [opcionesSelector, idiomaSelector, (_, idOpcion) => idOpcion],
    (opciones, idioma, idOpcion) => {
      const opcion = searchId(opciones, idOpcion)

      return {
        title: getTextoIdioma(opcion, 'title', idioma),
        subtitle: getTextoIdioma(opcion, 'subtitle', idioma),
        srcIcono: opcion.srcIcono,
        precio: number_format(opcion.precio, 2, ',', '.')
      }
    }
  )

export const getAcabados = createSelector(
  [estanciasSelector, idEstanciaActualSelector],
  (estancias, idEstanciaActual) => {
    const { acabados: idAcabados } = searchId(estancias, idEstanciaActual)

    return idAcabados
  }
)

export const getImagesVisible = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idEstanciaActualSelector
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idEstanciaActual) => {
    const result = []

    const estanciaActual = searchId(estancias, idEstanciaActual)

    result.push({
      id: estanciaActual.id,
      src: estanciaActual.src,
      width: estanciaActual.width,
      height: estanciaActual.height,
      visible: true,
      tipo: 'estancia'
    })

    estanciaActual.acabados.forEach(idAcabado => {
      const { opciones: idOpciones, parentAcabado } = searchId(
        acabados,
        idAcabado
      )

      const opcionesSeleccionadas1 =
        parentAcabado !== ''
          ? getSubopcionesValidas(
              idAcabado,
              parentAcabado,
              opciones,
              opcionesSeleccionadas
            ).subopcionesSeleccionadas
          : searchId(opcionesSeleccionadas, idAcabado).opciones

      idOpciones.forEach(idOpcion => {
        const opcion = searchId(opciones, idOpcion)

        if (opcion.src !== '') {
          result.push({
            id: opcion.id,
            src: opcion.src,
            width: opcion.width,
            height: opcion.height,
            visible: opcionesSeleccionadas1.indexOf(opcion.id) !== -1,
            tipo: 'opcion'
          })
        }
      })
    })

    return result
  }
)

export const getOpcionesSeleccionadas = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idiomaSelector
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idioma) => {
    const result = []

    estancias.forEach(estancia => {
      estancia.acabados.forEach(idAcabado => {
        const {
          title,
          multiple,
          opciones: opcionesAcabado,
          parentAcabado
        } = searchId(acabados, idAcabado)

        const idOpciones =
          parentAcabado !== ''
            ? getSubopcionesValidas(
                idAcabado,
                parentAcabado,
                opciones,
                opcionesSeleccionadas
              ).subopcionesSeleccionadas
            : searchId(opcionesSeleccionadas, idAcabado).opciones

        idOpciones.forEach(idOpcion => {
          const defaultOp = multiple
            ? false
            : opcionesAcabado.length === 0
            ? true
            : opcionesAcabado[0] === idOpcion

          const opcion = searchId(opciones, idOpcion)

          const index = result.length

          result.push({
            id: opcion.id,
            idEstancia: estancia.id,
            srcBase: estancia.src,
            idAcabado,
            index,
            acabadoTitle: searchId(title, idioma).texto,
            title: getTextoIdioma(opcion, 'title', idioma),
            subtitle: getTextoIdioma(opcion, 'subtitle', idioma),
            srcIcono: opcion.srcIcono,
            srcOpcion: opcion.src,
            precio: number_format(opcion.precio, 2, ',', '.'),
            default: defaultOp
          })
        })
      })
    })

    return result
  }
)

export const getOpcionesSeleccionadasPdf = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idiomaSelector
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idioma) => {
    return estancias.map(estancia => {
      const precio = getSubtotalAcabados(
        estancia.acabados,
        acabados,
        opciones,
        opcionesSeleccionadas
      )

      const acabados1 = estancia.acabados.map(idAcabado => {
        const acabado = searchId(acabados, idAcabado)

        const idOpciones =
          acabado.parentAcabado !== ''
            ? getSubopcionesValidas(
                idAcabado,
                acabado.parentAcabado,
                opciones,
                opcionesSeleccionadas
              ).subopcionesSeleccionadas
            : searchId(opcionesSeleccionadas, idAcabado).opciones

        const opciones1 = idOpciones.map(idOpcion => {
          const opcion = searchId(opciones, idOpcion)

          return {
            id: idOpcion,
            title: getTextoIdioma(opcion, 'title', idioma),
            subtitle: getTextoIdioma(opcion, 'subtitle', idioma),
            srcIcono: opcion.srcIcono,
            src: opcion.src,
            precio: number_format(opcion.precio, 2, ',', '.'),
            srcIconoPdf: opcion.srcIconoPdf
          }
        })

        return {
          title: getTextoIdioma(acabado, 'title', idioma),
          opciones: opciones1
        }
      })

      return {
        id: estancia.id,
        title: getTextoIdioma(estancia, 'title', idioma),
        src: estancia.src,
        acabados: acabados1,
        subtotal: number_format(precio, 2, ',', '.'),
        pdfIconosLinea: estancia.pdfIconosLinea
      }
    })
  }
)

export const getEstanciaActualTitle = createSelector(
  [estanciasSelector, idEstanciaActualSelector, idiomaSelector],
  (estancias, idEstanciaActual, idioma) => {
    const estancia = searchId(estancias, idEstanciaActual)
    return getTextoIdioma(estancia, 'title', idioma)
  }
)

export const getPrevNextEstancias = createSelector(
  [estanciasSelector, idEstanciaActualSelector, idiomaSelector],
  (estancias, idEstanciaActual, idioma) => {
    const result = { prev: null, next: null }

    const iLen = estancias.length

    if (iLen === 0 || iLen === 1) return result

    for (let i = 0; i < iLen; i++) {
      if (estancias[i].id === idEstanciaActual) {
        if (i === 0) {
          result.prev = null

          result.next = {
            path: `/${idioma}/${estancias[1].path}`,
            src: estancias[1].src,
            width: estancias[1].width,
            height: estancias[1].height
          }

          return result
        }
        if (i === estancias.length - 1) {
          result.prev = {
            path: `/${idioma}/${estancias[i - 1].path}`,
            src: estancias[i - 1].src,
            width: estancias[i - 1].width,
            height: estancias[i - 1].height
          }
          result.next = null
          return result
        }

        result.prev = {
          path: `/${idioma}/${estancias[i - 1].path}`,
          src: estancias[i - 1].src,
          width: estancias[i - 1].width,
          height: estancias[i - 1].height
        }
        result.next = {
          path: `/${idioma}/${estancias[i + 1].path}`,
          src: estancias[i + 1].src,
          width: estancias[i + 1].width,
          height: estancias[i + 1].height
        }
        return result
      }
    }
  }
)
