import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getEndMenu } from '../../modules/reducerDimensions'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import './MenuSlider.css'

import { setPositionY } from './MenuScroll'

export let getSetY = null

const MenuSlider = ({ visible }) => {
  const end = useSelector(getEndMenu)

  const [y, setY] = useState(0)
  getSetY = setY

  return (
    <div className="slider-container">
      <Slider
        onChange={e => {
          if (visible) {
            setPositionY(((100 - e) * end) / 100)
          }
        }}
        vertical
        min={0}
        max={100}
        step={1}
        defaultValue={100}
        value={100 - (100 * y) / end}
      />
    </div>
  )
}

export default MenuSlider
