import textos from './initialStateTextos'

export const initialState = {
  textos,
  estancias: [
    {
      id: 'e0',
      title: [
        { id: 'es', texto: 'Salón' },
        { id: 'en', texto: 'Salón Ingles' }
      ],
      nameButton: [
        { id: 'es', texto: 'Salón' },
        { id: 'en', texto: 'Salón Ingles' }
      ],
      path: 'salon',
      acabados: ['a0', 'a1'],
      src: 'images/salon/LAR TERRA_Salón_Base_0.jpg',
      width: 3500,
      height: 1969,
      pdfIconosLinea: 2
    },
    {
      id: 'e1',
      title: [
        { id: 'es', texto: 'Cocina' },
        { id: 'en', texto: 'Cocina Ingles' }
      ],
      nameButton: [
        { id: 'es', texto: 'Cocina' },
        { id: 'en', texto: 'Cocina Ingles' }
      ],
      path: 'cocina',
      acabados: ['a2', 'a3', 'a4'],
      src: 'images/cocina/LAR TERRA_Cocina_base_0_0.jpg',
      width: 3500,
      height: 1969,
      pdfIconosLinea: 2
    },
    {
      id: 'e2',
      title: [
        { id: 'es', texto: 'Dormitorio' },
        { id: 'en', texto: 'Dormitorio Ingles' }
      ],
      nameButton: [
        { id: 'es', texto: 'Dormitorio' },
        { id: 'en', texto: 'Dormitorio Ingles' }
      ],
      path: 'dormitorio',
      acabados: ['a5', 'a6'],
      src: 'images/dormitorio/LAR-TERRA_Dormitorio-principal_suelo-02_0.jpg',
      width: 4000,
      height: 3077,
      pdfIconosLinea: 2
    },
    {
      id: 'e3',
      title: [{ id: 'es', texto: 'Baño' }, { id: 'en', texto: 'Baño Ingles' }],
      nameButton: [
        { id: 'es', texto: 'Baño' },
        { id: 'en', texto: 'Baño Ingles' }
      ],
      path: 'baño',
      acabados: ['a7', 'a8', 'a9', 'a10', 'a11'],
      src: 'images/baño/LAR TERRA_Baño_base.jpg',
      width: 4000,
      height: 3077,
      pdfIconosLinea: 3
    }
  ],
  acabados: [
    {
      id: 'a0',
      title: [
        { id: 'es', texto: 'Solería' },
        { id: 'en', texto: 'Solería ingles' }
      ],
      opciones: ['op0', 'op1', 'op2', 'op3', 'op4', 'op5'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a1',
      title: [
        { id: 'es', texto: 'Puertas' },
        { id: 'en', texto: 'Puertas Ingles' }
      ],
      opciones: ['op6', 'op7'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a2',
      title: [
        { id: 'es', texto: 'Mobiliario' },
        { id: 'en', texto: 'Mobiliario Ingles' }
      ],
      opciones: ['op8', 'op9', 'op10', 'op11'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a3',
      title: [
        { id: 'es', texto: 'Suelos' },
        { id: 'en', texto: 'Suelos Ingles' }
      ],
      opciones: ['op12', 'op13', 'op14'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a4',
      title: [
        { id: 'es', texto: 'Encimeras' },
        { id: 'en', texto: 'Encimeras Ingles' }
      ],
      opciones: ['op15', 'op16', 'op17'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a5',
      title: [
        { id: 'es', texto: 'Solería' },
        { id: 'en', texto: 'Solería Ingles' }
      ],
      opciones: [
        'op18',
        'op19',
        'op20',
        'op21',
        'op22',
        'op23',
        'op24',
        'op25'
      ],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a6',
      title: [
        { id: 'es', texto: 'Puertas' },
        { id: 'en', texto: 'Puertas Ingles' }
      ],
      opciones: ['op26', 'op27'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a7',
      title: [
        { id: 'es', texto: 'Alicatados' },
        { id: 'en', texto: 'Alicatados Ingles' }
      ],
      opciones: [
        'op28',
        'op29',
        'op30',
        'op31',
        'op32',
        'op33',
        'op34',
        'op35',
        'op36',
        'op37',
        'op38',
        'op39',
        'op40',
        'op41'
      ],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a8',
      title: [
        { id: 'es', texto: 'Mueble' },
        { id: 'en', texto: 'Mueble Ingles' }
      ],
      opciones: ['op42', 'op43', 'op44'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a9',
      title: [
        { id: 'es', texto: 'Sanitarios' },
        { id: 'en', texto: 'Sanitarios Ingles' }
      ],
      opciones: ['op45', 'op46'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a10',
      title: [
        { id: 'es', texto: 'Mampara' },
        { id: 'en', texto: 'Mampara Ingles' }
      ],
      opciones: ['op47', 'op48'],
      multiple: true,
      parentAcabado: 'a9'
    },
    {
      id: 'a11',
      title: [{ id: 'es', texto: 'Bidé' }, { id: 'en', texto: 'Bidé Ingles' }],
      opciones: ['op49'],
      multiple: true,
      parentAcabado: ''
    }
  ],
  opciones: [
    {
      id: 'op0',
      title: [
        { id: 'es', texto: 'Porcelánico gris' },
        { id: 'en', texto: 'Porcelánico gris ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/suelo5_1_0.png',
      precio: 1000,

      src: '',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op1',
      title: [
        { id: 'es', texto: 'Porcelánico roble' },
        { id: 'en', texto: 'Porcelánico roble ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/suelo6_0_0.png',
      precio: 2000,

      src: 'images/salon/LAR-TERRA_Salón_Suelo-06_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op2',
      title: [
        { id: 'es', texto: 'Porcelánico ceniza' },
        { id: 'en', texto: 'Porcelánico ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/suelo7_1_0.png',
      precio: 0,

      src: 'images/salon/LAR-TERRA_Salón_Suelo-07_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op3',
      title: [
        { id: 'es', texto: 'Porcelánico beige' },
        { id: 'en', texto: 'Porcelánico beige ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/suelo1_2_0.png',
      precio: 0,

      src: 'images/salon/LAR-TERRA_Salón_Suelo-01_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op4',
      title: [
        { id: 'es', texto: 'Porcelánico ceniza' },
        { id: 'en', texto: 'Porcelánico ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/suelo2_2_0.png',
      precio: 0,

      src: 'images/salon/LAR-TERRA_Salón_Suelo-02_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op5',
      title: [
        { id: 'es', texto: 'Porcelánico gris' },
        { id: 'en', texto: 'Porcelánico gris ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/suelo3_1_0.png',
      precio: 0,

      src: 'images/salon/LAR-TERRA_Salón_Suelo-03_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op6',
      title: [
        { id: 'es', texto: 'Lacadas en blanco' },
        { id: 'en', texto: 'Lacadas en blanco ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/puerta1_0_0.png',
      precio: 0,

      src: '',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op7',
      title: [
        { id: 'es', texto: 'Lisa en madera natural' },
        { id: 'en', texto: 'Lisa en madera natural ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/salon/puerta2_0_0.png',
      precio: 0,

      src: 'images/salon/LAR-TERRA_Salón_Puertas-02_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op8',
      title: [
        { id: 'es', texto: 'Madera' },
        { id: 'en', texto: 'Madera ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/mobiliario3_0.png',
      precio: 0,

      src: '',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op9',
      title: [
        { id: 'es', texto: 'Blanco mate' },
        { id: 'en', texto: 'Blanco mate ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/mobiliario1_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Mobiliario-01_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op10',
      title: [
        { id: 'es', texto: 'Gris oscuro mate' },
        { id: 'en', texto: 'Gris oscuro mate ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/acabado2_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Mobiliario-02_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op11',
      title: [
        { id: 'es', texto: 'Blanco mate y madera' },
        { id: 'en', texto: 'Blanco mate y madera ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/mobiliario4_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Mobiliario-04_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op12',
      title: [
        { id: 'es', texto: 'Beige' },
        { id: 'en', texto: 'Beige ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/suelo11_0.png',
      precio: 0,

      src: '',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op13',
      title: [
        { id: 'es', texto: 'Ceniza' },
        { id: 'en', texto: 'Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/suelo2_1_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Suelo-ceniza_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op14',
      title: [{ id: 'es', texto: 'Gris' }, { id: 'en', texto: 'Gris ingles' }],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/suelo3_0_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Suelo-gris_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op15',
      title: [
        { id: 'es', texto: 'Blanco norte' },
        { id: 'en', texto: 'Blanco norte ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/encimera1_0.png',
      precio: 0,

      src: '',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op16',
      title: [
        { id: 'es', texto: 'Marengo' },
        { id: 'en', texto: 'Marengo ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/encimera2_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Encimera-02_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op17',
      title: [
        { id: 'es', texto: 'Gris expo' },
        { id: 'en', texto: 'Gris expo ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/cocina/encimera3_0.png',
      precio: 0,

      src: 'images/cocina/LAR-TERRA_Cocina_Encimera-03_0.png',
      width: 3500,
      height: 1969,

      srcIconoPdf: []
    },
    {
      id: 'op18',
      title: [
        { id: 'es', texto: 'Tarima Burlington' },
        { id: 'en', texto: 'Tarima Burlington ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria2.png',
      precio: 0,

      src: '',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op19',
      title: [
        { id: 'es', texto: 'Tarima continental' },
        { id: 'en', texto: 'Tarima continental ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria1.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-suelo-01_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op20',
      title: [
        { id: 'es', texto: 'Tarima barrel' },
        { id: 'en', texto: 'Tarima barrel ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria3.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-suelo-03_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op21',
      title: [
        { id: 'es', texto: 'Tarima gris' },
        { id: 'en', texto: 'Tarima gris ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria4.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-suelo-04_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op22',
      title: [
        { id: 'es', texto: 'Tarima fósil' },
        { id: 'en', texto: 'Tarima fósil ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria5.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-suelo-05_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op23',
      title: [
        { id: 'es', texto: 'Porcelánico roble' },
        { id: 'en', texto: 'Porcelánico roble ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria6.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-suelo-06_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op24',
      title: [
        { id: 'es', texto: 'Porcelánico ceniza' },
        { id: 'en', texto: 'Porcelánico ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria7.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-suelo-07_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op25',
      title: [
        { id: 'es', texto: 'Porcelánico gris' },
        { id: 'en', texto: 'Porcelánico gris ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/soleria8.png',
      precio: 0,

      src: 'images/dormitorio/LAR TERRA_Dormitorio suelo 08_0_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op26',
      title: [
        { id: 'es', texto: 'Lacadas en blanco' },
        { id: 'en', texto: 'Lacadas en blanco ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/puerta1_1.png',
      precio: 0,

      src: '',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op27',
      title: [
        { id: 'es', texto: 'Lisa en madera natural' },
        { id: 'en', texto: 'Lisa en madera natural ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/dormitorio/puerta2_1.png',
      precio: 0,

      src: 'images/dormitorio/LAR-TERRA_Dormitorio-puertas-02_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op28',
      title: [
        { id: 'es', texto: 'Parcial Sonata Ceniza' },
        { id: 'en', texto: 'Parcial Sonata Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado1_0_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-1-B.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/11.png',
        'images/baño/12.png',
        'images/baño/13.png'
      ]
    },
    {
      id: 'op29',
      title: [
        { id: 'es', texto: 'Completo Sonata Ceniza' },
        { id: 'en', texto: 'Completo Sonata Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado1_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-1_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/11.png',
        'images/baño/12.png',
        'images/baño/13.png'
      ]
    },
    {
      id: 'op30',
      title: [
        { id: 'es', texto: 'Parcial Gard Marfil' },
        { id: 'en', texto: 'Parcial Gard Marfil ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado2_0_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-2-B.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/21.png',
        'images/baño/22.png',
        'images/baño/23.png'
      ]
    },
    {
      id: 'op31',
      title: [
        { id: 'es', texto: 'Completo Gard Marfil' },
        { id: 'en', texto: 'Completo Gard Marfil ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado2_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-2_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/21.png',
        'images/baño/22.png',
        'images/baño/23.png'
      ]
    },
    {
      id: 'op32',
      title: [
        { id: 'es', texto: 'Parcial Gard Ceniza' },
        { id: 'en', texto: 'Parcial Gard Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado3_0_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-3-B.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/31.png',
        'images/baño/32.png',
        'images/baño/33.png'
      ]
    },
    {
      id: 'op33',
      title: [
        { id: 'es', texto: 'Completo Gard Ceniza' },
        { id: 'en', texto: 'Completo Gard Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado3_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-3_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/31.png',
        'images/baño/32.png',
        'images/baño/33.png'
      ]
    },
    {
      id: 'op34',
      title: [
        { id: 'es', texto: 'Parcial Move Gris' },
        { id: 'en', texto: 'Parcial Move Gris ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado4_0_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-4-B.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/41.png',
        'images/baño/42.png',
        'images/baño/43.png'
      ]
    },
    {
      id: 'op35',
      title: [
        { id: 'es', texto: 'Completo Move Gris' },
        { id: 'en', texto: 'Completo Move Gris ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado4_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-4_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/41.png',
        'images/baño/42.png',
        'images/baño/43.png'
      ]
    },
    {
      id: 'op36',
      title: [
        { id: 'es', texto: 'Parcial Sunset Ceniza' },
        { id: 'en', texto: 'Parcial Sunset Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado6_0_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-6-B.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/61.png',
        'images/baño/62.png',
        'images/baño/63.png'
      ]
    },
    {
      id: 'op37',
      title: [
        { id: 'es', texto: 'Completo Sunset Ceniza' },
        { id: 'en', texto: 'Completo Sunset Ceniza ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado6_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-6_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/61.png',
        'images/baño/62.png',
        'images/baño/63.png'
      ]
    },
    {
      id: 'op38',
      title: [
        { id: 'es', texto: 'Parcial Interior Beige' },
        { id: 'en', texto: 'Parcial Interior Beige ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado7_0_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-7-B.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/71.png',
        'images/baño/72.png',
        'images/baño/73.png'
      ]
    },
    {
      id: 'op39',
      title: [
        { id: 'es', texto: 'Completo Interior Beige' },
        { id: 'en', texto: 'Completo Interior Beige ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado7_0.png',
      precio: 0,

      src: 'images/baño/LAR-TERRA_Baño_alicatado-7_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/71.png',
        'images/baño/72.png',
        'images/baño/73.png'
      ]
    },
    {
      id: 'op40',
      title: [
        { id: 'es', texto: 'Parcial Move Marfil' },
        { id: 'en', texto: 'Parcial Move Marfil ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado5_0_0.png',
      precio: 0,

      src: '',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/51.png',
        'images/baño/52.png',
        'images/baño/51_0.png'
      ]
    },
    {
      id: 'op41',
      title: [
        { id: 'es', texto: 'Completo Move Marfil' },
        { id: 'en', texto: 'Completo Move Marfil ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/alicatado5_0.png',
      precio: 0,

      src: '',
      width: 4000,
      height: 3077,

      srcIconoPdf: [
        'images/baño/51.png',
        'images/baño/52.png',
        'images/baño/51_0.png'
      ]
    },
    {
      id: 'op42',
      title: [
        { id: 'es', texto: 'Pedestal' },
        { id: 'en', texto: 'Pedestal ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/lavabo1_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_lavabo 11_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op43',
      title: [
        { id: 'es', texto: 'Mueble blanco' },
        { id: 'en', texto: 'Mueble blanco ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/lavabo3_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_lavabo 31_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op44',
      title: [
        { id: 'es', texto: 'Mueble cedro' },
        { id: 'en', texto: 'Mueble cedro ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/lavabo2_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_lavabo 21_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op45',
      title: [
        { id: 'es', texto: 'Grifo ducha' },
        { id: 'en', texto: 'Grifo ducha ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/grifo_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_grifo ducha1_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [],
      subOpciones: ['op47']
    },
    {
      id: 'op46',
      title: [
        { id: 'es', texto: 'Bañera' },
        { id: 'en', texto: 'Bañera ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/bañera_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_bañera1_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: [],
      subOpciones: ['op48']
    },
    {
      id: 'op47',
      title: [
        { id: 'es', texto: 'Mampara ducha' },
        { id: 'en', texto: 'Mampara ducha ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/mampara_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_mampara1_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op48',
      title: [
        { id: 'es', texto: 'Mampara bañera' },
        { id: 'en', texto: 'Mampara bañera ingles' }
      ],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/mamparab.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_mampara bañera.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    },
    {
      id: 'op49',
      title: [{ id: 'es', texto: 'Bidé' }, { id: 'en', texto: 'Bidé ingles' }],
      subtitle: [{ id: 'es', texto: 'Base1' }, { id: 'en', texto: 'Base1' }],
      srcIcono: 'images/baño/bide_0.png',
      precio: 0,

      src: 'images/baño/LAR TERRA_Baño_bidé1_0.png',
      width: 4000,
      height: 3077,

      srcIconoPdf: []
    }
  ],
  idEstanciaActual: 'e0',
  opcionesSeleccionadas: [
    { id: 'a0', opciones: ['op0'] },
    { id: 'a1', opciones: ['op6'] },
    { id: 'a2', opciones: ['op8'] },
    { id: 'a3', opciones: ['op12'] },
    { id: 'a4', opciones: ['op15'] },
    { id: 'a5', opciones: ['op18'] },
    { id: 'a6', opciones: ['op26'] },
    { id: 'a7', opciones: ['op28'] },
    { id: 'a8', opciones: ['op42'] },
    { id: 'a9', opciones: ['op45'] },
    { id: 'a10', opciones: [] },
    { id: 'a11', opciones: [] }
  ],

  precioBase: 0,
  idioma: 'es',

  url: {
    pathname: '',
    href: '',
    search: ''
  }
}

export default initialState
