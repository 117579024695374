import React, { useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'

import { closeInformation } from '../../modules/reducerUI'

import {
  getTextoInformacion,
  getTextoCerrarInformacion
} from '../../modules/getTextos'

import { useSpring, animated } from 'react-spring'

const useStyles = theme =>
  createUseStyles({
    modal: {
      position: 'absolute',
      zIndex: 2,
      width: '90%',
      top: '50%',
      left: '50%',
      padding: '30px',
      backgroundColor: 'white',
      color: ({ theme }) => theme.textColor,
      maxWidth: '700px',
      transform: 'translate(-50%, -50%)',
      '-webkit-transform': 'translate(-50%, -50%)',
      textAlign: 'center'
    },
    [`@media screen and (max-width: ${theme.mediaMovil})`]: {
      modal: {
        top: '75%'
      }
    },
    modalCerrar: {
      marginTop: '10px',
      fontSize: '1.4em',
      cursor: 'pointer',
      color: ({ theme }) => theme.colorPrincipal
    }
  })

const WindowInformation = () => {
  const dispatch = useDispatch()
  const open = useSelector(state => state.ui.openInformation)
  const textoInformacion = useSelector(getTextoInformacion)
  const cerrarInformacion = useSelector(getTextoCerrarInformacion)

  const dcloseInformation = useCallback(() => dispatch(closeInformation()), [
    dispatch
  ])

  const { opacity, display } = useSpring({
    opacity: open ? 1 : 0,
    display: open ? 'block' : 'none'
  })

  const theme = useTheme()
  const classes = useStyles(theme)({ theme })

  return (
    <animated.div className={classes.modal} style={{ opacity, display }}>
      {textoInformacion}
      <div className={classes.modalCerrar} onClick={dcloseInformation}>
        {cerrarInformacion}
      </div>
    </animated.div>
  )
}

export default WindowInformation
