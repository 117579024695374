import React from 'react'
import { createUseStyles } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getEstancias } from '../../modules/reducer'

import {
  getButtonEstanciaX,
  getEndSliderButtons,
  setPositionX
} from '../../modules/reducerDimensions'

import { useSpring, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-with-gesture'

import ButtonEstancia from './ButtonEstancia'

const useStyles = createUseStyles({
  buttonEstancias: {
    display: 'inline-flex'
  }
})

let getValues = null

const f = p => () => p

export let moving = false

const getX = (deltaX, estanciasX, endSlider, positionX) => {
  if (endSlider > 0) return 0

  for (let i = 0, iLen = estanciasX.length; i < iLen; i++) {
    if (estanciasX[i].right + deltaX + positionX >= 0) {
      if (-estanciasX[i].left < endSlider) {
        return endSlider
      } else {
        return -estanciasX[i].left
      }
    }
  }

  return endSlider
}

const ButtonsSlider = () => {
  const dispatch = useDispatch()
  const estancias = useSelector(getEstancias)
  const estanciasX = useSelector(getButtonEstanciaX)
  const endSlider = useSelector(getEndSliderButtons)
  const positionX = useSelector(state => state.dimensions.positionX)

  getValues = f({ estanciasX, endSlider, positionX })

  const [bind, { delta, down }] = useGesture({
    onMove: e => {
      if (Math.abs(e.delta[0]) > 10) {
        moving = true
      }
    },
    onDown: e => {
      moving = false
    },
    onUp: e => {
      if (moving) {
        const values = getValues()
        dispatch(
          setPositionX(
            getX(
              e.delta[0],
              values.estanciasX,
              values.endSlider,
              values.positionX
            )
          )
        )
      }
    }
  })

  const { x } = useSpring({ x: down ? delta[0] + positionX : positionX })

  const classes = useStyles()

  return (
    <animated.div
      {...bind()}
      className={classes.buttonEstancias}
      style={{
        transform: interpolate([x], x => {
          return `translate3d(${x}px,0,0)`
        })
      }}
    >
      {estancias.map((idEstancia, index) => (
        <ButtonEstancia key={idEstancia} id={idEstancia} index={index} />
      ))}
    </animated.div>
  )
}

export default ButtonsSlider
