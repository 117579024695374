import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

//import 'gridlex/src/gridlex.scss'

import './gridlex.scss'

import { createUseStyles, ThemeProvider } from 'react-jss'
import Theme from './Theme'

import { Switch, Route, withRouter } from 'react-router-dom'
import useRouter from './components/Estancia/useRouter'

import { useTransition, animated } from 'react-spring'

import MeasureHeight from './components/Images/MeasureHeight'
import Estancia from './components/Estancia/Estancia'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import OpcionesSeleccionadas from './components/Estancia/OpcionesSeleccionadas'

import PdfLoading from './PdfLoading'

const HeaderR = withRouter(Header)

const useStyles = createUseStyles({
  app: {
    flexWrap: 'nowrap',
    height: '100vh'
  },
  [`@media screen and (max-width: ${Theme.mediaScroll})`]: {
    app: {
      height: 'auto'
    }
  },
  wrapperApp: {
    flex: 1,
    overflowX: 'hidden'
  }
})

const App = () => {
  const heightHeader = useSelector(state => state.dimensions.heightHeader)

  const { location } = useRouter()

  useEffect(() => {
    function handleScroll(e) {
      e.preventDefault()
    }

    document.addEventListener('touchmove', handleScroll, { pasive: false })

    return function cleanup() {
      document.removeEventListener('touchmove', handleScroll, { pasive: false })
    }
  })

  const transitions = useTransition(location, location => location.pathname, {
    from: {
      /*opacity: 0 */
      transform: 'none' //'translate3d(0%,0,0)'
    },
    enter: {
      /*opacity: 1 */
      transform: 'none' //'translate3d(0%,0,0)'
    },
    leave: {
      //opacity: 1
      transform: 'none'
    },
    easing: t => t * t * t * t
  })

  const mq = window.matchMedia(`(max-width: ${Theme.mediaMovil})`)

  let marginTop = 0

  if (mq.matches) {
    marginTop = heightHeader
  }

  const classes = useStyles()

  return (
    <ThemeProvider theme={Theme}>
      <div className={`grid-column-noGutter ${classes.app}`}>
        <HeaderR />

        <div
          className={`col-12 grid-noGutter ${classes.wrapperApp}`}
          style={{ marginTop }}
        >
          <MeasureHeight />
          {transitions.map(({ item, props, key }) => (
            <animated.div
              key={key}
              className="col-12 grid-noGutter"
              style={props}
            >
              <Switch location={item}>
                <Route path="/:idioma/:id" component={Estancia} />
                <Route path="/:idioma" component={Estancia} />
                <Route exact path="/" component={Estancia} />
                <Route component={Estancia} />
              </Switch>
            </animated.div>
          ))}
        </div>

        <PdfLoading />
        <Footer />
        <OpcionesSeleccionadas />
      </div>
    </ThemeProvider>
  )
}

export default App
