import React from 'react'
import { useDispatch } from 'react-redux'

import { setUrl } from './modules/reducer'

//import { BrowserRouter as Router } from 'react-router-dom'
import { HashRouter as Router } from 'react-router-dom'

import App from './App'

import { Font } from '@react-pdf/renderer'

const AppWithRouter = () => {
  const dispatch = useDispatch()

  const pathname = window && window.location && window.location.pathname
  const origin = window && window.location && window.location.origin
  //const href = window && window.location && window.location.href
  //const search = window && window.location && window.location.search

  const url = `${origin}${pathname}` //href.replace(search, '')

  dispatch(setUrl(url))

  Font.register({ family: 'PoppinsBold', src: `${url}Poppins-Bold.ttf` })

  Font.register({ family: 'Poppins', src: `${url}Poppins-Regular.ttf` })

  //basename={pathname}
  return (
    <Router>
      <App />
    </Router>
  )
}

export default AppWithRouter
