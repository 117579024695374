import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleGeneratePdf, togglePdfLoading } from '../../modules/reducerPDF'

import { BlobProvider } from '@react-pdf/renderer'

export const MyPdfDownloadLink = ({ document: doc, idEnlace }) => {
  const dispatch = useDispatch()
  const generatePdf = useSelector(state => state.pdf.generatePdf)

  const dgeneratePdf = useCallback(() => {
    dispatch(toggleGeneratePdf())
    dispatch(togglePdfLoading())
  }, [dispatch])

  if (!doc) {
    console.log('You should pass a valid document to PDFDownloadLink')
    return null
  }

  return generatePdf ? (
    <BlobProvider document={doc}>
      {({ blob, url, loading, error }) => {
        if (!loading) {
          const enlace = document.getElementById(idEnlace)
          enlace.href = url
          enlace.click()
          dgeneratePdf()
        }
        return null
      }}
    </BlobProvider>
  ) : null
}

export default MyPdfDownloadLink
